
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: ($block-padding * 1/3) ($block-padding * 2);

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
